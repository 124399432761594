<template>
    <section class="xbts-wallet-fiat mt-3">
        <div v-show="step === 'fiat'">
            <table class="table table-striped table-borderless tb-assets mt-2 w-100" v-if="balancesFiat">
                <tbody>
                <tr>
                    <th>{{$t('assets.asset')}}</th>
                    <th>{{$t('wallet.available')}}</th>
                    <th>{{$t('wallet.order')}}</th>
                </tr>
                <tr v-bind:key="item.symbol" v-for="item in balancesFiat">
                    <td><img :src="'images/assets/' + item.symbol.toLowerCase() + '.png'"/>{{item.symbol.split('.')[1]}}</td>
                    <td>
                        <span v-show="item.amount > 0" class="text-success">{{(item.amount / 10 ** item.precision).toFixed(item.precision)}}</span>
                        <span v-show="item.amount === 0">{{(item.amount / 10 ** item.precision).toFixed(item.precision)}}</span>
                    </td>
                    <td>
                        <span>{{(item.limit_orders / 10 ** item.precision).toFixed(item.precision)}}</span>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>

        <div v-show="step === 'withdraw'">
            <WithdrawFiat/>
        </div>




    </section>
</template>

<script>
import WithdrawFiat from '@/components/wallet/WithdrawFiat';
import eventBus from '@/plugins/event-bus';

export default {
    name: "WalletFiat",
    components: {
        WithdrawFiat,
    },
    data() {
        return {
            step: 'fiat',
            symbol: 'XBTSX.RUB'
        }
    },
    methods: {
      async withdrawFiat(id) {
          await eventBus.emit('withdraw:fiat', id);
          this.step = 'withdraw';
      },
    },
    computed: {
        gateways() {
            return this.$store.getters['wallet/gateways'];
        },
        balancesFiat() {
            return this.$store.getters['accounts/balancesFiat'];
        },
    }
}
</script>

<style scoped>

</style>
