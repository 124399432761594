<template>
  <section class="xbts-wallet-defi mt-3">
    <p v-show="loadBalances" class="text-warning">Loading LP balances..</p>
    <table class="table table-striped table-borderless tb-assets mt-2 w-100" v-if="balancesLp">
      <tbody>
      <tr>
        <th>LP</th>
        <th>{{ $t('market.pair') }}</th>
        <th>{{ $t('wallet.available') }}</th>
      </tr>
      <tr class="cursor-pointer" @click="routeTo('/pool/' + item.symbol)" v-bind:key="item.symbol"
          v-for="item in balancesLp"
          v-show="item.amount > 0 && item.symbol.includes(coinFilter.toUpperCase())">

        <td>
        <span class="symbol-a">
            <img v-if="allAssets[item.pool.A.symbol]" :src="'images/assets/' + item.pool.A.symbol.toLowerCase() + '.png'"/>
            <img v-if="!allAssets[item.pool.A.symbol]" :src="'images/assets/coin.png'"/>
        </span>
          <span class="symbol-b">
            <img v-if="allAssets[item.pool.B.symbol]" :src="'images/assets/' + item.pool.B.symbol.toLowerCase() + '.png'"/>
            <img v-if="!allAssets[item.pool.B.symbol]" :src="'images/assets/coin.png'"/>
          </span>
          <span v-if="!$root.isMobile">{{ item.symbol }}</span>
        </td>

        <td><span v-html="formatAssetPrefix(item.pool.A.symbol.replace('XBTSX.', '')).html"></span>/<span
            v-html="formatAssetPrefix(item.pool.B.symbol.replace('XBTSX.', '')).html"></span></td>
        <td>
          <span v-show="item.amount > 0"
                class="text-success">{{ (item.amount / 10 ** item.precision).toFixed(item.precision) }}</span>
          <span v-show="item.amount === 0">{{ (item.amount / 10 ** item.precision).toFixed(item.precision) }}</span>
          <!--
          <span v-show="item.percent > 0"
                class="small text-warning"><br/>{{ (item.pool.amountTotal[currency] * (item.percent / 100)).toFixed(2) }} {{ currSymbols[currency] }}</span>
                -->
        </td>


      </tr>
      <!--
      <tr>
        <td></td>
        <td>{{ $t('wallet.total') }}</td>
        <td><span class="text-warning">{{ formatNum(total[currency], '# ##0.') }} {{ currSymbols[currency] }}</span>
        </td>
      </tr>
      -->
      </tbody>
    </table>
  </section>
</template>

<script>
import {currSymbols, search, xbtsAssets, nativeAssets, xbtsFiat, otherAssets} from "@/config";

export default {
  name: "WalletDefi",
  data() {
    return {
      timerBalances: null,
      loadBalances: false,
      coinFilter: '',
      hideNull: -1,
      allAssets: {},
      currSymbols: currSymbols
    }
  },
  methods: {
    async hideZero() {
      await this.$store.dispatch('wallet/setHideZero', this.isHideZero === 0 ? -1 : 0);
    }
  },
  computed: {
    total() {
      const keys = Object.keys(this.balancesLp);
      let result = {
        rub: 0,
        usd: 0,
        eur: 0,
        cny: 0,
      };
      for (let i = 0; i < keys.length; i++) {
        result.rub = result.rub + this.balancesLp[keys[i]].pool.amountTotal.rub * this.balancesLp[keys[i]].percent / 100;
        result.usd = result.usd + this.balancesLp[keys[i]].pool.amountTotal.usd * this.balancesLp[keys[i]].percent / 100;
        result.eur = result.eur + this.balancesLp[keys[i]].pool.amountTotal.eur * this.balancesLp[keys[i]].percent / 100;
        result.cny = result.cny + this.balancesLp[keys[i]].pool.amountTotal.cny * this.balancesLp[keys[i]].percent / 100;
      }
      return result;
    },
    isHideZero() {
      return this.$store.getters['wallet/hideZero'];
    },
    gateways() {
      return this.$store.getters['wallet/gateways'];
    },
    balancesLp() {
      return this.$store.getters['accounts/balancesLp'];
    },
    currency() {
      return this.$store.getters['paprika/currency'];
    },
  },
  async created() {
    if (this.$root.account) {
      let before_loadtime = new Date().getTime();

      clearTimeout(this.timerBalances);
      this.loadBalances = true;
      this.timerBalances =  setTimeout(async () => {
        await this.$store.dispatch('accounts/updateLpBalances', {
          userId: this.$root.account.account.id,
          lpId: null
        });
        let aftr_loadtime = new Date().getTime();
        let pgloadtime = (aftr_loadtime - before_loadtime) / 1000
        console.log('load time balances lp', pgloadtime, 'sec');
        this.loadBalances = false;
      }, 350);


    }


    const assets = xbtsAssets.concat(nativeAssets, xbtsFiat, otherAssets);
    for (let i = 0; i < assets.length; i++) {
      this.allAssets[assets[i]] = true;
    }
  }
}
</script>

<style scoped>

</style>
