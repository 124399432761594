<template>
    <div class="row">

        <div class="col-md-6">
            <div class="card">
                <div class="card-body">
            <h3 class="card-title">1. {{$t('wallet.fiat_1')}}</h3>
            <div>
                <b-form-group
                        :label="$t('wallet.currency')"
                        label-for="input_currency">
            <b-form-select id="input_currency" variant="primary" class="text-white" v-model="selected" :options="options"></b-form-select>
                </b-form-group>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <div class="mt-4">
                    <b-button variant="outline-primary" class="w-100">
                        PAYEER {{balancesFiat[selected].symbol.replace('XBTSX.', '')}}
                        <br/>+1% {{$t('market.fee')}}
                    </b-button>
                    </div>
                </div>
            </div>

                </div>
            </div>

        </div>

        <div class="col-md-6">
            <div class="card">
                <div class="card-body">
                    <h3 class="card-title">2. {{$t('wallet.fiat_2')}} PAYEER</h3>

                    <div>
                        <span class="float-right small">
                            {{$t('wallet.available')}} <span class="cursor-pointer text-info" @click="amount = (balancesFiat[selected].amount / 10 ** balancesFiat[selected].precision).toFixed(balancesFiat[selected].precision)">{{(balancesFiat[selected].amount / 10 ** balancesFiat[selected].precision).toFixed(balancesFiat[selected].precision)}}</span> {{balancesFiat[selected].symbol.replace('XBTSX.', '')}}
                        </span>
                        <b-form-group
                                :label="$t('wallet.amount')"
                                label-for="input_amount">
                            <b-input-group :append="balancesFiat[selected].symbol.replace('XBTSX.', '')">
                                <b-form-input
                                        id="input_amount"
                                        placeholder=""
                                        type="text" v-model="amount"></b-form-input>
                            </b-input-group>
                        </b-form-group>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import eventBus from '@/plugins/event-bus';
export default {
    name: "WithdrawFiat",
    data() {
        return {
            amount: null,
            selected: '1.3.5887',
            options: [
                { value: '1.3.5887', text: 'RUB Russian Ruble' },
                { value: '1.3.5888', text: 'USD Dollar' },
                { value: '1.3.5889', text: 'EUR Euro' },
            ]
        }
    },
    computed: {
        balancesFiat() {
            return this.$store.getters['accounts/balancesFiat'];
        },
    },
    async created() {
        eventBus.on('withdraw:fiat', async (id) => {
            this.selected = id;
        });
    }
}
</script>

<style scoped>

</style>
