<template>
  <section class="xbts-wallet-crypto mt-3">
    <div class="row">
      <div class="col-md-12">
        <div aria-label="SmartHolder Period" class="btn-group w-100" role="group">
          <button @click="depositWithdraw('deposit')" class="btn text-uppercase btn-outline-primary"
                  type="button"><i class="fa fa-level-down"></i> {{ $t('wallet.deposit') }}
          </button>
          <button @click="depositWithdraw('withdraw')" class="btn text-uppercase btn-outline-primary"
                  type="button"><i class="fa fa-level-up"></i> {{ $t('wallet.withdraw') }}
          </button>
          <button disabled class="btn text-uppercase btn-outline-primary"
                  type="button">{{ $t('wallet.transfer') }} <i class="icon-paper-plane"></i>
          </button>
        </div>
      </div>
    </div>

    <table class="table table-condensed tb-assets mt-2 w-100"
           :class="isHideZero === -1 ? 'table-striped' : ''"
           v-if="balances">
      <thead>
      <tr>
        <th>{{ $t('assets.asset') }}</th>
        <th>{{ $t('wallet.available') }}</th>
        <th v-if="!$root.isMobile">{{ $t('wallet.order') }}</th>
        <th>{{ $t('wallet.cost') }}</th>
        <th v-if="!$root.isMobile">{{ $t('wallet.action') }}</th>
        <th v-if="!$root.isMobile">Network</th>
      </tr>
      </thead>
      <tbody>

      <!-- BTS -->
      <tr v-if="portfolio">
        <td>
          <router-link :to="'/asset/BTS'"><img :src="'images/assets/bts.png'" alt="bts"/>BTS</router-link>
        </td>
        <td>
                    <span v-if="portfolio['1.3.0']" class="text-success">
                        {{ (portfolio['1.3.0'].amount / 10 ** portfolio['1.3.0'].precision).toFixed(portfolio['1.3.0'].precision) }}
                    </span>
          <span v-if="!portfolio['1.3.0']" class="">0.00</span>
        </td>
        <td v-if="!$root['isMobile']">
          <span
              v-if="portfolio['1.3.0']">{{ (portfolio['1.3.0'].limit_orders / 10 ** portfolio['1.3.0'].precision).toFixed(portfolio['1.3.0'].precision) }}</span>
        </td>
        <td>
                    <span v-if="portfolio['1.3.0']">
                        {{ currSymbols[currency] }} {{ formatNum(portfolio['1.3.0'].total * btsPrice[currency].price) }}
                    </span>
        </td>
        <td v-if="!$root['isMobile']">

          <div class="row">
            <div class="col-6">
              <router-link class="text-info" :to="'/wallet/deposit/BTS'">
                <i class="mdi mdi-arrow-bottom-right"></i> {{ $t('wallet.deposit') }}
              </router-link>
            </div>

            <div class="col-6">
              <router-link :to="'/wallet/withdraw/BTS'" class="text-info">{{ $t('wallet.withdraw') }} <i
                  class="mdi mdi mdi-arrow-top-right"></i></router-link>
            </div>

          </div>

        </td>
        <td v-if="!$root['isMobile']">
          <span class="badge badge-primary">BitShares</span>
        </td>
      </tr>
      <!-- BTS END-->
      <tr v-bind:key="item.symbol" v-for="item in balances"
          v-show="(item.amount > isHideZero && item.symbol.includes(coinFilter.toUpperCase()) && item.symbol !=='BTS')">
        <td>
          <router-link :to="'/asset/' + item.symbol"><img
              :src="'images/assets/' + item.symbol.replace('XBTSX.', '').toLowerCase() + '.png'"/>{{ item.symbol.replace('XBTSX.', '') }}
          </router-link>
        </td>
        <td>
          <span v-show="item.amount > 0"
                class="text-success">{{ (item.amount / 10 ** item.precision).toFixed(item.precision) }}</span>
          <span v-show="item.amount === 0">{{ (item.amount / 10 ** item.precision).toFixed(item.precision) }}</span>
        </td>
        <td v-if="!$root.isMobile">
          <span>{{ (item.limit_orders / 10 ** item.precision).toFixed(item.precision) }}</span>
        </td>
        <td>
                    <span v-if="tickers['BTS_' + item.symbol.replace('XBTSX.', '')]" v-b-tooltip.html.bot>
                       {{ currSymbols[currency] }}{{ formatNum((tickers['BTS_' + item.symbol.replace('XBTSX.', '')].last * item.total) * btsPrice[currency].price) }}
                    </span>

        </td>
        <td v-if="!$root.isMobile">
          <div class="row">
            <div class="col-6">
              <router-link class="text-info" :to="'/wallet/deposit/' + item.symbol">
                <i class="mdi mdi-arrow-bottom-right"></i> {{ $t('wallet.deposit') }}
              </router-link>
            </div>
            <div class="col-6">
              <router-link :to="'/wallet/withdraw/' + item.symbol" class="text-info">{{ $t('wallet.withdraw') }} <i
                  class="mdi mdi mdi-arrow-top-right"></i></router-link>
            </div>
          </div>
        </td>
        <td v-if="!$root.isMobile">
          <span title="Native Blockchain" v-show="gateways.coins[item.symbol]"
                class="ml-1 badge badge-primary">COIN</span>
          <span title="Ethereum network [ETH]" v-show="gateways.eth[item.symbol]"
                class="ml-1 badge badge-primary">ETH</span>
          <span title="Binance Smart Chain [BSC]" v-show="gateways.bsc[item.symbol]"
                class="ml-1 badge badge-warning text-dark">BSC</span>
          <span title="EOS Chain" v-show="gateways.eos[item.symbol]"
                class="ml-1 badge badge-success">EOS</span>
          <span title="WAVES Platform [WAVES]" v-show="gateways.waves[item.symbol]"
                class="ml-1 badge badge-info">WAVES</span>
        </td>
      </tr>
      </tbody>
    </table>
  </section>
</template>

<script>
import {currSymbols} from "@/config";

export default {
  name: "WalletCrypto",
  data() {
    return {
      opType: 'wallet',
      coinFilter: '',
      hideNull: -1,
      currSymbols: currSymbols,
    }
  },
  methods: {
    async depositWithdraw(op) {
      await this.$router.push('/wallet/' + op + '/XBTSX.BTC');
    },
    async hideZero() {
      await this.$store.dispatch('wallet/setHideZero', this.isHideZero === 0 ? -1 : 0);
    }
  },
  computed: {
    currency() {
      return this.$store.getters['paprika/currency'];
    },
    btsPrice() {
      return this.$store.getters['xbts/featuredPrice']['bts']
    },
    tickers() {
      return this.$store.getters['paprika/tickers'];
    },
    gateways() {
      return this.$store.getters['wallet/gateways'];
    },
    balances() {
      return this.$store.getters['accounts/balances'];
    },
    portfolio() {
      return this.$store.getters['accounts/portfolio'];
    },
    isHideZero() {
      return this.$store.getters['wallet/hideZero'];
    }
  },
}
</script>

<style scoped>

</style>
