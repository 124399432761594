<template>
  <section class="xbts-wallet">
    <div class="card w-100">
      <div class="card-body">
        <div class="row">
          <div class="col-6">
            <span class="x-crumbs"><router-link to="/">XBTS</router-link> <i
                class="mdi mdi-chevron-right"></i> <span>{{ $t('wallet.wallet') }}</span></span>
          </div>
          <div class="col-6">
                  <span v-if="cost[currency]" class="float-right badge badge-success"
                        :class="!$root['isMobile'] ? 'badge-big' : ''" :title="$t('wallet.cost')+ ' ' + $t('wallet.total')"
                        v-b-tooltip.html.bot>
                    {{ currSymbols[currency] }} {{ formatNum(cost[currency]) }}
                  </span>
          </div>
        </div>


        <hr/>
        <div class="row">
          <div class="col-md-6">
            <button :class="type ==='crypto' ? 'btn-primary' : 'btn-secondary'" @click="type = 'crypto'"
                    class="btn btn-sm mr-2"
                    type="button">Crypto
            </button>
            <button :class="type ==='fiat' ? 'btn-primary' : 'btn-secondary'" @click="type = 'fiat'"
                    class="btn btn-sm mr-2"
                    type="button">Fiat
            </button>
            <button :class="type ==='defi' ? 'btn-primary' : 'btn-secondary'" @click="type = 'defi'"
                    class="btn btn-sm mr-2"
                    type="button">DeFi
            </button>

            <!--
            <button @click="hideZero" class="btn btn-sm link mr-2"
                    type="button">
              <span v-show="isHideZero < 0" class="text-uppercase">{{ $t('wallet.hide_zero') }}</span>
              <span v-show="isHideZero > -1" class="text-uppercase text-info">{{ $t('wallet.show_all') }}</span>
            </button>
            -->

          </div>
          <!--
            <div class="col-md-6 mt-1" v-show="type === 'crypto'">
                <form v-show="!$root.isMobile" action="#">
                    <div class="input-group">
                        <div class="input-group-prepend hover-cursor" id="navbar-search-icon">
            <span class="input-group-text" id="search">
              <i class="ti-search"></i>
            </span>
                        </div>
                        <input
                                v-model="coinFilter"
                                aria-describedby="search"
                                aria-label="search"
                                class="form-control"
                                id="navbar-search-input"
                                :placeholder="$t('wallet.search_coins')"
                                type="text"
                        />
                    </div>
                </form>
            </div>
            -->
        </div>


        <div v-if="type === 'crypto'">
          <p v-show="loadBalances" class="text-warning">Loading balances..</p>
          <WalletCrypto/>
        </div>

        <div v-if="type === 'fiat'">
          <WalletFiat/>
        </div>

        <div v-if="type === 'defi'">
          <WalletDefi/>
        </div>




      </div>
    </div>

  </section>
</template>

<script>
import WalletFiat from '@/components/wallet/WalletFiat';
import WalletDefi from '@/components/wallet/WalletDefi';
import WalletCrypto from '@/components/wallet/WalletCrypto';
import {currSymbols} from "@/config";
import eventBus from "@/plugins/event-bus";

export default {
  name: "Wallet",
  components: {
    WalletCrypto,
    WalletFiat,
    WalletDefi,
  },
  data() {
    return {
      loadBalances: false,
      timerBalances: null,
      timerBalancesLp: null,
      currSymbols: currSymbols,
      coinFilter: '',
      type: 'crypto',
      breadcrumbs: [
        {
          text: "XBTS",
          href: "/#/"
        },
        {
          text: this.$t('wallet.wallet'),
          active: true
        },
      ],
      cost: {
        rub: 0,
        usd: 0,
        cny: 0,
      }
    }
  },
  computed: {
    gateways() {
      return this.$store.getters['wallet/gateways'];
    },
    assets() {
      return this.$store.getters['assets/assets'];
    },
    balances() {
      return this.$store.getters['accounts/balances'];
    },
    portfolio() {
      return this.$store.getters['accounts/portfolio'];
    },
    isHideZero() {
      return this.$store.getters['wallet/hideZero'];
    },
    balancesLp() {
      return this.$store.getters['accounts/balancesLp'];
    },
    currency() {
      return this.$store.getters['paprika/currency'];
    },
    btsPrice() {
      return this.$store.getters['xbts/featuredPrice']['bts'];
    },
    tickers() {
      return this.$store.getters['paprika/tickers'];
    },
  },
  methods: {
    async hideZero() {
      await this.$store.dispatch('wallet/setHideZero', this.isHideZero === 0 ? -1 : 0);
    },
    async totalLp() {
      const keys = Object.keys(this.balancesLp);
      let result = {
        rub: 0,
        usd: 0,
        cny: 0,
      };
      for (let i = 0; i < keys.length; i++) {
        result.rub = result.rub + this.balancesLp[keys[i]].pool.amountTotal.rub * this.balancesLp[keys[i]].percent / 100;
        result.usd = result.usd + this.balancesLp[keys[i]].pool.amountTotal.usd * this.balancesLp[keys[i]].percent / 100;
        result.cny = result.cny + this.balancesLp[keys[i]].pool.amountTotal.cny * this.balancesLp[keys[i]].percent / 100;
      }
      return result;
    },
    async totalCrypto() {
      const keys = Object.keys(this.balances);
      let result = {
        rub: 0,
        usd: 0,
        cny: 0,
      };

      if (this.portfolio['1.3.0']) {
        result['rub'] = this.portfolio['1.3.0'].total * this.btsPrice['rub'].price;
        result['usd'] = this.portfolio['1.3.0'].total * this.btsPrice['usd'].price;
        result['cny'] = this.portfolio['1.3.0'].total * this.btsPrice['cny'].price;
      }
      for (let i = 0; i < keys.length; i++) {
        if (this.tickers['BTS_' + this.balances[keys[i]].symbol.replace('XBTSX.', '')]) {
          result.rub = result.rub + (this.tickers['BTS_' + this.balances[keys[i]].symbol.replace('XBTSX.', '')].last * this.balances[keys[i]].total) * this.btsPrice['rub'].price;
          result.usd = result.usd + (this.tickers['BTS_' + this.balances[keys[i]].symbol.replace('XBTSX.', '')].last * this.balances[keys[i]].total) * this.btsPrice['usd'].price;
          result.cny = result.cny + (this.tickers['BTS_' + this.balances[keys[i]].symbol.replace('XBTSX.', '')].last * this.balances[keys[i]].total) * this.btsPrice['cny'].price;
        }
      }
      return result;
    },
    async calcTotal() {
      //const totalLp = await this.totalLp();
      const totalCrypto = await this.totalCrypto();


      this.cost = {
        rub: totalCrypto['rub'],
        usd: totalCrypto['usd'],
        cny: totalCrypto['cny'],
      };
      /*
      if (!totalLp['rub']) {

      } else {
        this.cost = {
          rub: totalLp['rub'] + totalCrypto['rub'],
          usd: totalLp['usd'] + totalCrypto['usd'],
          cny: totalLp['cny'] + totalCrypto['cny'],
        };

      }

       */

    }
  },
  async created() {
    if (!this.$root.pin) {
      await this.$router.push('/auth-pages/login');
    } else {
      let before_loadtime = new Date().getTime();

      clearTimeout(this.timerBalances);
      this.loadBalances = true;
      this.timerBalances =  setTimeout(async () => {
        await this.$store.dispatch('accounts/updateBalances', {
          userId: this.$root.account.account.id,
          lpId: null
        });
        await this.calcTotal();
        let aftr_loadtime = new Date().getTime();
        let pgloadtime = (aftr_loadtime - before_loadtime) / 1000
        console.log('load time balances', pgloadtime, 'sec');
        this.loadBalances = false;

      }, 350);

      /*
      clearTimeout(this.timerBalancesLp);
      this.timerBalancesLp =  setTimeout(async () => {
        await this.$store.dispatch('accounts/updateLpBalances', {
          userId: this.$root.account.account.id,
          lpId: null
        });
        let aftr_loadtime = new Date().getTime();
        let pgloadtime = (aftr_loadtime - before_loadtime) / 1000
        console.log('load time balances lp', pgloadtime, 'sec');
        this.loadBalances = false;
        await this.calcTotal();
      }, 3000);

       */




    }
  }
}
</script>

<style scoped>

</style>
